import { HeaderHeight } from '../models/PageModel'
import { breakpoints } from '../constants/Layout'

/**
 * Used to calculate the current header nav height.
 *
 */
export function getHeaderHeight(): number {
  return window.innerWidth > breakpoints.navMainLarge ? HeaderHeight.desktop : HeaderHeight.mobile
}

/**
 * Used to smooth scroll to element in the window.
 *
 * @param element
 */
export function scrollTo(element): void {
  window.scroll({
    behavior: 'smooth',
    left: 0,
    top: element.getBoundingClientRect().top + window.scrollY - getHeaderHeight(),
  })
}
